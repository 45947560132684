import {ChatMessage} from './chat-dialog';
import {MessageContent} from './chat-notification';
import {ChannelUser} from '@nit-core/models/channel';
import {ChatType} from '@nit-core/global/domain/enums';

export class Room {
  admins: RoomUser[];
  name: string;
  _id: string;
  owner: RoomUser;
  users: RoomUser[];
  blocked: (RoomUser | string)[];
  type: ChatType;
  lastMsg: LastMessage;
  school: number;
  semester: number;
  class: RoomClass;
  pinnedMessage: ChatMessage | null;
  muted: RoomAction;
  createdAt: Date;
  updatedAt: Date;
  unread: number;
  pinned: RoomAction;
  unread_v2: number;

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item?.createdAt ? new Date(item.createdAt) : null;
    this.updatedAt = item?.updatedAt ? new Date(item.updatedAt) : null;
    this.owner = item.owner ? new RoomUser(item.owner) : null;
    this.class = item.class ? new RoomClass(item.class) : null;
    this.muted = item.muted ? new RoomAction(item.muted) : null;
    this.pinned = item.pinned ? new RoomAction(item.pinned) : null;
    this.lastMsg = item.lastMsg ? new LastMessage(item.lastMsg) : null;
    this.users = item.users ? item.users.map(x => new RoomUser(x)) : [];
    this.admins = item.admins ? item.admins.map(x => new RoomUser(x)) : [];
    this.blocked = item.blocked ? item.blocked.map(x => new RoomUser(x)) : [];
  }
}

export class RoomClass {
  _id: string;
  name: string;
  subgroup: string;

  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class RoomAction {
  createdAt: Date;
  room: string;
  updatedAt?: Date;
  user: string;
  _id: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
    this.createdAt = item?.createdAt ? new Date(item.createdAt) : null;
    this.updatedAt = item?.updatedAt ? new Date(item.updatedAt) : null;
  }
}

export class RoomUser {
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName: string;
  online?: boolean;
  permissions?: any;
  _id: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class PermissionUsersRoom {
  role: string;
  schoolId: number;
}

export class RoomHeaderAction {
  text: string;
  icon?: string;
  constructor(item: any = {}) {
    Object.assign(this, item);
  }
}

export class SearchRoom {
  rooms: Room[];
  messages: MessageContent[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.rooms = item.rooms ? item.rooms.map((x: any) => new Room(x)) : [];
    this.messages = item.messages ? item.messages.map((x: any) => new MessageContent(x)) : [];
  }
}

export class LastMessage {
  createdAt: Date;
  files?: File[];
  targetId?: string;
  targetType?: string;
  text?: string;
  _id?: string;
  user?: ChannelUser;
  readBy?: string[];

  constructor(item: any = {}) {
    Object.assign(this, item);
    this.user = item.user ? new ChannelUser(item.user) : null;
    this.createdAt = item?.createdAt ? new Date(item.createdAt) : null;
  }
}
