export class ChatCall {
  _id: string;
  room: ChatCallRoom;
  type: string;
  users: ChatCallUser[];
  owner: ChatCallUser;
  createdAt: Date;
  updatedAt: Date;
  school: number;
  mediaType: string;

  // helper
  ownerImage: string;
  shortAbbr: string;
  longAbbr: string;

  get relatedChatUrl(): string {
    return `/chat/group/${this.room.group}/${this.room.type}/${this.room._id}`;
  }


  constructor(obj: any = {}) {
    Object.assign(this, obj);
    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    this.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : undefined;
    this.ownerImage = this.room.type === 'direct' ? this.users?.find(x => this._id === x._id)?.image : undefined;

    this.longAbbr = (this.room.type === 'direct' ? this.owner.username : this.room.name)
      ?.split(/(\s+)/).filter(x => !!x.trimSymbol(' ')).map(x => x[0]).join('').slice(0, 3);
    this.shortAbbr = this.longAbbr?.slice(0, 2).trimSymbol(' ');
  }
}

export class ChatMessage {
  _id: string;
  type: 'room' | 'direct' = 'direct';
  unread: number;
  room: string;
  user: string;
  group: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  message: MessageContent;

  // helper
  ownerImage: string;
  abbr: string;

  get relatedChatUrl(): string {
    return `/chat/group/${this.group}/${this.type}/${this.room}`;
  }

  constructor(obj: any = {}) {
    Object.assign(this, obj);
    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    this.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : undefined;
    this.message = obj.message ? new MessageContent(obj.message) : undefined;

    this.ownerImage = this.type === 'direct' ? this.message?.user?.image : undefined;

    this.abbr = (this.type === 'direct' ? this.message?.user?.username : this.name)
      ?.split(/(\s+)/).filter(x => !!x.trimSymbol(' ')).map(x => x[0]).join('').slice(0, 2);
  }
}

export class MessageContent {
  urls: string[];
  files: string[];
  type: string;
  active: boolean;
  _id: string;
  text: string;
  user: ChatCallUser;
  mentions: string[];
  room: string;
  createdAt: Date;
  updatedAt: Date;

  // helper
  cleanText: string;

  constructor(obj: any = {}) {
    Object.assign(this, obj);

    this.createdAt = obj.createdAt ? new Date(obj.createdAt) : undefined;
    this.updatedAt = obj.updatedAt ? new Date(obj.updatedAt) : undefined;

    if (this.text) {
      const span = document.createElement('span');
      span.innerHTML = this.text;
      this.cleanText = span.textContent || span.innerText;
    }
  }
}

export interface ChatCallUser {
  _id: string;
  username: string;
  image?: string;
  fullName?: string;
}

export interface ChatCallRoom {
  type: 'room' | 'direct';
  _id: string;
  name?: string;
  group: string;
  class?: ChatCallRoomClass;
}

export interface ChatCallRoomClass {
  _id: string;
  name?: string;
}

export interface ChatCallWhiteboard {
  _id: string;
  key: string;
  owner: string;
  media: string;
  users: string[];
}
